.product-filter-card {
    input[type="checkbox"] {
        position: relative;
        width: 1.25rem;
        height: 1.25rem;
        color: $black;
        border: 1px solid lighten($dark, 25%);
        border-radius: .25rem;
        appearance: none;
        outline: 0;
        cursor: pointer;
        margin-right: .5rem;
        margin-left: 0;
        transition-property: background-color, border-color;
        transition-duration: .5s;
        transform: translateY(.25rem);
    
        &::before {
            position: absolute;
            content: '';
            display: block;
            top: .2rem;
            left: .42rem;
            width: .33rem;
            height: .66rem;
            border-style: solid;
            border-color: $white;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
            opacity: 0;
        }
    
        &:checked {
            color: $white;
            border-color: $primary;
            background-color: $primary;
            transition-property: background-color, border-color;
            transition-duration: .25s;
        }

        &::before {
            opacity: 1;
        }
    }

    .form-check {
        display: flex;
        align-items: baseline;
        padding-left: 0;
    }

    .form-check-label {
        width: 80%;
    }
}
