$single-park-background-color: none !default;
$single-park-border-color: $result-box-border-color !default;
$single-park-cta-background-color: $lighter !default;

.single-park-result {
    //@include result-box;
    //background-color: $single-park-background-color;
    //border-color: $single-park-border-color;

    .single-park-result-cta {
        background: $single-park-cta-background-color;
    }

    &.is-sticky-result {
        background-color: rgba($secondary, .025);
        border: .125rem solid $secondary;
        box-shadow: 0 .375rem 0.5rem rgba($secondary, .25);

        .single-park-result-cta {
            background: rgba($secondary, .05) !important;
            border-top-color: rgba($secondary, .4) !important;
        }
    }

    &.is-premium {
        background-color: rgba($primary, .005);
        border: .125rem solid $primary;
        box-shadow: 0 .375rem 0.5rem rgba($primary, .25);

        .single-park-result-cta {
            background: rgba($primary, .05);
            border-top-color: rgba($primary, .33) !important;
        }
    }

    .park-image {
        .badge {
            position: absolute;
            z-index: 1;
            left: .5rem;
            top: .5rem;
            font-size: 1rem;
        }
    }

    ul {
        list-style: none;
        padding: 0;
    }

    li {
        &::before {
            content: '\004a';
            color: $secondary;
            font-family: 'Breakfree Font';
            margin-right: 1rem;
        }
    }

    &.grid-size {
        li {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            font-size: .75rem;

            &::before {
                margin-right: .5rem;
                font-size: .75rem;
            }
        }
    }
}

.smallText { /* <-- I don't like this, investigate */
    font-size: 1rem;
}

.search-result-slider {
    .slick-next {
        right: -.5rem;
    }
    .slick-prev,
    .slick-next {
        &::before {
            height: 2rem;
        }
    }

    .slick-slide {
        img {
            object-fit: cover;
            height: 19rem;
        }
    }
}
    
.textOverlay {
    position: absolute;
    bottom: 0px;
}

// ParkGallery
.park-page-gallery {
    .slick-prev {
        left: calc(33% + 1.5rem);
    }
    .slick-next {
        right: calc(33% + 1.5rem);
    }
    .slick-slide {
        padding: 0 .5rem;

        &:not(.slick-center) {
            img {
                opacity: .5;
            }
        }
    }
}

// Park page tabs
.park-page-tabs {
    background-color: $lighter; 
    grid-auto-flow: column;

    .nav-item {
        padding: 1rem 0;
        text-align: center;
        border: 0;
        

        &.active {
            background-color: $primary;
            color: $white;
        }
    }
}