.alt-dates-slider {
    padding: 0 2rem;

    .slick-prev,
    .slick-next {
        height: 1.75rem;
        width: 1.75rem;
    }

    .slick-prev {
        left: 0;
    }

    .slick-next {
        right: 0;
    }
}

.date-card {
    border: 0;
    //@include site-box-shadow(darken($light, 5%));

    &.alt-card {
        margin: 0 .5rem;
    }

    .h4 {
        color: inherit;
    }

    &.date-selected {

        //@include site-box-shadow(rgba($primary, .25));
        .card-body {
            background-color: lighten($primary, 60%) !important;
            border-radius: 0 !important;

            p {
                color: #35BACE
            }
        }

        .card-footer {
            background-color: $primary;
            color: $white;
            border-top-color: $primary !important;
        }
    }
}