// Backgrounds

//.bg-dark-a66 {
//    background-color: transparentize($dark, .33);
//}
//
//.bg-primary-a33 {
//    background-color: transparentize($primary, .66);
//}
//
//.bg-primary-a66 {
//    background-color: transparentize($primary, .33);
//}
//
//.bg-secondary-a33 {
//    background-color: transparentize($secondary, .66);
//}
//
//.bg-secondary-a66 {
//    background-color: transparentize($secondary, .33);
//}

.backdrop-blur {
    backdrop-filter: blur(.125rem);
}

.bg-darker-a66 {
    background-color: transparentize($darker, .33);
}

.bg-black-a33 {
    background-color: transparentize($black, .66);
}

.bg-black {
    background-color: $black;
}

.bg-white {
    background-color: $white;
}

.bg-grey {
    background-color: $grey;
}

.bg-blue {
    background-color: #35BACE;

    &.bg-blured {
        opacity: 0.7;
    }
}

.bg-dark-blue {
    background-color: $dark-blue;
}

.bg-white-a90 {
    background-color: transparentize($white, .1);
}

.bg-size-cover {
    background-size: cover;
}

.bg-size-contain {
    background-size: contain;
}

.bg-size-90 {
    background-size: 90%;
}

.bg-no-repeat {
    background-repeat: no-repeat;
}

// Colors
.text-black {
    color: $black;
}

// Forms
textarea {
    border-radius: 1.5rem !important;
}
.range-slider {
    -webkit-appearance: none;

    &::-webkit-slider-thumb {
        -webkit-appearance: none;
        background-color: $primary;
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 50%;
        border: .1875rem solid white;
        box-shadow: 0 0 0 .0625rem lighten($dark, 25%);
        cursor: pointer;
        transition: .5s ease-in-out;
        transform: translateY(-50%);
    }
    &:hover::-webkit-slider-thumb {
        box-shadow: 0 0 0 .0625rem $primary;
    }

    &::-moz-range-thumb {
        background-color: $primary;
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 50%;
        border: .125rem solid white;
        box-shadow: 0 0 0 1rem red;
        cursor: pointer;
        transition: .5s ease-in-out;
        @include site-box-shadow;
    }

	&::-webkit-slider-runnable-track {
		width: 60%;
        border-radius: 1rem;
		height: .0625rem;
		background-color: lighten($dark, 25%);
		transition: background-color .5s;
		cursor: pointer;  
    }

    &::-moz-range-track {
		height: .125rem;
		background-color: $light;
		transition: background-color .5s;
		cursor: pointer;
	}

	&:hover::-webkit-slider-runnable-track {
		background-color: $primary;
	}
}

/*

.125 = 2
.25 = 4
.5 = 8

*/


// Sizes
.text-sm {
    font-size: $font-size-sm;
}

.font-size-3 { // Would like to map these to same step sizes in .p- classes
    font-size: 1rem !important;
}

.text-lg {
    font-size: $font-size-lg;
}

// Borders
.border-dotted {
    border-style: dotted;
}

// Outline
.outline-none {
    outline: none;
}

// Cursors
.cursor-pointer {
    cursor: pointer;
}

.cursor-help {
    cursor: help;
}

// Selection
::selection {
    color: $white;
    background: $primary;
}

// Spin
.spin {
    animation-name: spin;
    animation-duration: 1500ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@keyframes spin {
  from {
      transform:rotate(0deg);
  }
  to {
      transform:rotate(360deg);
  }
}

.content-box {
    @include result-box;
}

// Assists with sticking an element to the top of a column
@include media-breakpoint-up(md) {
    .stick-to-top-of-column {
        position: sticky;
        top: 91px; // header height (75px) + 16px
    }
}
