// FAQ tabs
.faqs-tabs {
    border: none;

    .nav-item {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #495057;
        background-color: $white;
        border-color: #dee2e6;
        margin-bottom: 1rem;
        flex-basis: 0;
        flex-grow: 1;
        font-weight: 400;
        text-align: center;
        padding: 0.5rem 0.75rem;
        font-size: 1.25rem;
        line-height: 1.5;
        border-radius: 3rem;
        transition: .3s;

        &:not(:first-child) {
            margin-left: 2rem;
        }
    }

    .nav-item.active {
        color: $white;
        background-color: $primary;
        border-color: $primary;
    }
}