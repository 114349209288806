.accommodation-listing {
    position: relative;

    .accommodation-listing-image {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        flex: 1 1 auto;
        display: flex;
        flex-flow: column;

        .slick-slider {
            display: flex;
            flex: 1 1 auto;

            .slick-list {
                display: flex;
                flex-flow: column;
                width: 100%;

                .slick-track {
                    flex: 1 1 auto;

                    .slick-slide {
                        display: flex;
                        flex-flow: column;

                        div {
                            flex: 1 1 auto;

                            img {
                                height: 100%;
                                object-fit: cover;
                            }
                        }
                    }
                }
            }
        }

        .accommodation-listing-remaining {
            right: auto;
            left: .5rem;
        }
    }

    .accommodation-listing-remaining {
        font-weight: 700;
        position: absolute;
        left: auto;
        right: .5rem;
        border-radius: 99px;
        top: .5rem;
    }

    .accommodation-listing-content {
        width: 50%;

        .saving {
            color: $green;
        }

        button {
            width: 150px;
        }
    }

    .old-price {
        text-decoration: line-through;
    }
}

@include media-breakpoint-only(xs) {
    .accommodation-listing {
        .accommodation-listing-image,
        .accommodation-listing-content {
            width: 100%;
            min-height: 8rem;
        }

        .accommodation-listing-remaining {
            top: 0.5rem;
            bottom: auto;
        }
    }
}
