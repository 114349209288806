$footer-nav-bg-color: $dark !default;
$footer-nav-bottom-bg-color: $darker !default;
$footer-trust-bg-color: $lighter !default;

footer {
    color: $light;

    .footer-circle {
        @include site-box-shadow(darken($light, 5%));
        height: 300px;
        width: 300px;
        border: 1px solid darken($light, 5%);
        border-radius: 50%;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        display: flex;
        flex-direction: column;
        background-color: $white;
        color: $dark;

        @include media-breakpoint-down(sm) {
            height: 256px;
            width: 256px;
            
            h2 {
                font-size: 1.2rem;
            }
        }
    }

    .footer-nav {
        background-color: $footer-nav-bg-color;

        a {
            font-size:.8rem;
        }
    }

    .footer-nav-bottom {
        background-color: $footer-nav-bottom-bg-color;
    }

    .footer-trust {
        background-color: $footer-trust-bg-color;
    }

    .social {
        color: $dark;
        display: inline-block;
        text-decoration: none;
    }
}

#newsletter {
    .invalid-feedback {
        @media (min-width: 992px) {
            position: absolute;
        }
    }
}

.newsletter-floater {
    position: fixed;
    cursor: pointer;
    background-color: $primary;
    color: $white;
    padding: .5rem;
    bottom: -4rem;
    right: -4rem;
    width: 8rem;
    display: block;
    text-align: center;
    height: 8rem;
    transform: rotate(-45deg);
    border: .25rem solid $white;
    z-index: 2;
}

.newsletter-modal {
    .modal-header {
        position: absolute;
        right: 0;
        z-index: 1;
        border: none;
    }

    .close {
        font-size: 2.25rem;
        color: $white;
    }
    
    video {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.newsletter-modal-form {
    background-color: #eef4f9;
    z-index: 1;
    position: relative;
    border-radius: 20px;
}

.cookie-consent {
    position: fixed;
    background-color: rgba($white, .9);
    backdrop-filter: blur(2px);
    color: $black;
    padding: 1rem;
    bottom: 0;
    z-index: 999;
    font-size: bold;
} 
 /* New Footer */
 .col-one-fifth {
     @include make-col(1,5);
 }
 .col-two-fifths {
    @include make-col(2,5);
}
.col-four-fifths {
    @include make-col(4,5);
}

.col-one-fifth,
.col-two-fifths,
.col-four-fifths {
    @include media-breakpoint-down(sm) {
        width: 100%;
        max-width: 100%;
        flex: 0 0 100%;
    }
}

.footer-social-media {
    padding: 0 1rem 1rem;

    .social-icons {
        display: flex;
        flex-wrap: wrap;
    }

    @include media-breakpoint-up(md) {
        border-left: 1px solid $darker;
        padding: 1.25rem 1rem;

        .social-icons a {
            font-size: 1.5rem;
        }
    }
}