.truncated-text-alt {
    .copy {
        display: -webkit-box;
        -webkit-line-clamp: 999;
        -webkit-box-orient: vertical;  
        overflow: hidden;

        & ~ button {
            display: none;
        }

        &.truncated {
            -webkit-line-clamp: 3;
            line-height: 1.5em;
            max-height: 4.5em;

            & ~ button {
                display: inline-block;
            }
        }
    }    
}