// ********************
// *     Branding     *
// ********************

// Colours
$primary        : #15ABCF;
$secondary      : #F8D829;
$alt-primary    : #10afd2;
$warning        : #fde8be;

$grey-dark      : #5B5B5A;
$dark           : $grey-dark;
$darker         : darken($dark, 12%);

$light-grey     : #ededed;
$light          : $light-grey;
$lighter        : lighten($light, 5%);

$red            : #D13C1B;
$green          : #74b62e;
$dark-blue      : #0089FF;

$white          : #fff;
$black          : #000;
$grey           : #f2f2f2;

$theme-colors: (
    "lighter" : $lighter,
    "darker" : $darker
);

// Fonts
@import url("https://use.typekit.net/hvf7bsp.css");
$headings-font-weight: 500;

$body-color:                $dark;
$font-size-base:            1rem;
$font-family-safe:          'titillium-web', sans-serif;
$font-family-sans-serif:    'titillium-web', sans-serif;

// Border Radius
$border-radius:             .33rem;
$border-radius-lg:          .5rem;
$border-radius-sm:          .25rem;

// Header
$header-background: $dark;
$header-static: false; // Rename this, does not apply to 'static' positioning but colour change on scroll.
$header-nav-link-padding-x: 1rem;

// Hero
$hero-background-image: url('https://content.breakfreeholidays.co.uk/images/homepage/hero.jpg'); // must be relative to _hero.scss

// Forms
$form-label-margin-bottom: 0.1rem;

// Footer
$footer-nav-bg-color: $dark;
$footer-trust-bg-color: $light;

// ***************
// *     App     *
// ***************

@import './base/assets/scss/app.scss';
