$hero-background-image: none !default;

.masthead-loading {
    min-height: 36.5rem;
    height: 60vh;
    max-height: 100vh;
}

.masthead {
    @extend .masthead-loading;
    //background-image: $hero-background-image;
    background-color: $primary;
    background-size: cover;
    background-position: right center;
    background-repeat: no-repeat;
    position: relative;

    video {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    [data-mh-lead],
    [data-mh-sub] {
        color: $white;
        text-shadow: 0px 3px 6px #00000029;
    }

    [data-mh-lead] {
        font-size: 3.25rem;
    }

    [data-mh-sub] {
        font-size: 1.75rem;
        max-width: 580px;
    }

    .heading-img {
        max-height: 180px;
    }

    @include media-breakpoint-down(md) {
        [data-mh-lead] {
            font-size: 1.75rem;
        }

        [data-mh-sub] {
            font-size: 1rem;
        }

        .btn {
            font-size: 1rem;
        }
    }
}