.booking-page {
    .progressBar {
        background-color: darken($lighter, 3%);
    }

    .card {
        &:not(.active):hover {
            border-color: $light !important;
        }
    }

    .card-header {
        background-color: darken($lighter, 3%);

        &::after {
            content: '' !important;
            background-image: url('../../images/icons/double-arrow-down.svg');
            background-position: center;
            width: 15px;
            height: 30px;
        }

        &:hover {
            background-color: darken($lighter, 3%) !important;
            border-color: $light !important;
            color: unset !important;
        }
    }

    .img-container {
        img {
            width: 70px;
            height: 45px;
            object-fit: contain;
        }
    }

    .custom-select {
        border-radius: $input-border-radius;
    }

    .steps-container {
        @include media-breakpoint-only(xs) {
            flex-direction: column-reverse;
        }
    }

    .steps,
    .steps>div {
        display: flex;
        align-items: center;

        @include media-breakpoint-only(xs) {
            justify-content: center;
        }
    }

    .step {
        font-size: 19px;
        line-height: 19px;
        border-radius: $input-border-radius;
        border: 1px solid $primary;
        color: darken($lighter, 3%);
        position: relative;

        &.active {
            background: $primary;
            color: $primary;
        }

        &.completed {
            color: $primary;
            border: none;
        }
    }

    .divider {
        width: 79px;
        border-bottom: 1px dotted $primary;
        margin: 0 8px;
    }

    .step-title {
        @include media-breakpoint-only(xs) {
            font-size: 1rem;
            line-height: 30px;
            text-align: center;
            margin-top: 5px;
        }
    }

    .nav-pills .nav-link {
        border-radius: $input-border-radius;
    }

    .confirmation {
        .btn-primary {
            font-size: 1rem;
        }
    }

    .important-box {
        background: rgba(250, 179, 41, 0.2);
        margin: 34px 0;
    }

    @include media-breakpoint-only(xs) {
        .card .card-body .card-header-pills.full-width {
            flex-wrap: wrap;
        }

        .card .card-body .card-header-pills.full-width .nav-item {
            margin: 0 0 1rem;
        }
    }
}